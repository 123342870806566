<template>
    <v-row justify="center" class="ma-0 pa-0">
        <v-col cols="12" xl="11" lg="11" md="12" sm="12">

            <v-row justify="center" no-gutters>
                <span class="text-h4 text-md-h3 font-weight-light">About Us</span>
            </v-row>

            <!-- <v-divider v-if="isAdmin()" class="primary mt-4"/>
            <v-row v-if="isAdmin()" justify="center" align="center" class="mt-4 flex-wrap-reverse flex-lg-wrap" style="width: 100%;">
                <v-col v-for="product in products" :key="product.id" xl="3" lg="4" md="4" sm="8" xs="12" class="mb-6 mb-md-0" >
                    <PriceSheet @click="openCheckoutDialog(plan)" :plan="product"/>
                    <v-hover v-slot="{hover}">
                        <v-btn v-if="stripeCustomer.subscription.status === 'active' && stripeCustomer.subscription.plan.product != product.id" :disabled="!isAdmin()"  depressed block class="mt-4" :class="{ 'lighten-1' : hover }" :color="product.color" @click="switchSubscription(product)">{{product.name}}</v-btn>
                        <v-btn v-else-if="stripeCustomer.subscription.plan.product === product.id" depressed disabled block class="mt-4">You have {{product.name}}</v-btn>
                    </v-hover>
                </v-col>
            </v-row> -->

            <v-divider class="primary mt-4"/>
            <v-row justify="center" no-gutters>
                <span class="text-h5 font-weight-light mt-4">How it works</span>
            </v-row>

            <v-row justify="center" align="center">
                <v-col xl="4" lg="5" md="8" sm="10" xs="12">
                    <v-card color="primary" class="rounded-xl">
                        <v-carousel :continuous="false" class="custom-carousel rounded-xl" hide-delimiters hide-delimiter-background progress progress-color="white">
                            <v-carousel-item v-for="(scene, i) in scenes" :key="i" :transition="false" :reverse-transition="false">
                                <v-row justify="center" align="center" no-gutters style="height: 100%">
                                    <v-col cols="12" style="height: 65%" class="flex-grow-1 white">
                                        <v-img contain max-height="90%" min-height="90%" :src="scene.src"></v-img>
                                    </v-col>
                                    <v-col cols="12" style="height: 40%" class="flex-shrink-1">
                                        <v-card-title style="word-break: normal;" class="justify-center align-center">
                                            <span class="text-h5 font-weight-bold warning--text text-center mb-n5">{{scene.title}}</span>
                                            <!-- <span class="text-subtitle-2 font-weight-medium white--text text-center">{{scene.text}}</span> -->
                                        </v-card-title>
                                        <v-card-title style="word-break: normal;" class="justify-center align-center">
                                            <span class="text-subtitle-2 font-weight-medium white--text text-center">{{scene.text}}</span>
                                        </v-card-title>
                                    </v-col>
                                </v-row>
                            </v-carousel-item>
                        </v-carousel>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
// import firebase from "../../firebaseConfig.js";
// import PriceSheet from "./PriceSheet.vue";
// import Checkout from "./Checkout.vue";
export default {
    name: "about",
    metaInfo: {
        title: 'About - tawqin'
    },
    props: [],
    components: {
        // PriceSheet,
        // Checkout
     },
    data() {
        return {
            products: [],
            checkout: false,
            purchasePlan: null,
            scenes: [
            // { 
            //     slide: 1,
            //     title: "Thanks for using Tawq.in!",
            //     text: "This is a brief explaination showing you how to use the service.",
            //     src: require('../assets/tour/welcome.png'),
            // },
            // ADD: slide to show that you need to click the plus button top right
            { 
                slide: 2,
                title: "Choose Media and Language.",
                text: "Upload Audio or Video from your device, or paste in a URL from YouTube. Then choose the language you want to speak.",
                // ^ reword: video or audio directly from device, or copy and paste a video url from youtube to get started!
                src: require('../assets/tour/1.png'),
            },
            { 
                slide: 3,
                title: "Speak. Caption. And Edit.",
                text: "Press record and speak, press stop to finish. Your voice will convert and the caption will appear. Press the pencil to edit.",
                // text: "Connect your mobile device, and start tawqin when you want.",
                // ^ this needs more info and you don't have to connect your phone anymore.
                src: require('../assets/tour/2.png'),
            },
            { 
                slide: 4,
                title: "Upload your Post.",
                text: "Allow it to be seen by everyone, or just a group of your friends.",
                src: require('../assets/tour/3.png'),
            },
            { 
                slide: 5,
                title: "Contribute.",
                text: "Add your voice to other posts or have others add to yours.",
                // maybe add more info about how to contribute???
                src: require('../assets/tour/4.png'),
            },
            // Groups???
            // if so...include basic info.
            // groups posts are private and can only be seen by those in a group, ability for private contributions which can only be seen by the admin and uploaded user, admin can make comments on contributions.
            // { 
            //     slide: 6,
            //     title: "Need Help?",
            //     text: "If you ever have questions, just tap the help icon in the sidebar.",
            //     src: require('../assets/tour/9.png'),
            // },
            ],
        }
    },
    methods:{
        getStripeProducts(){
            console.log("getStripeProducts");
            this.$http.get(`stripe/products`).then((response) => {
                this.products = response.data.products.data;
                this.setProductPrices(response.data.prices.data);
            })
        },
        setProductPrices(prices){
            for (var p = 0; p < this.products.length; p++) {
                if( this.products[p].name === 'Basic Plan'){
                    this.products[p].color = 'secondary';
                } else if (this.products[p].name === 'Plus Plan') {
                    this.products[p].color = 'primary';
                }
                else if (this.products[p].name === 'Premium Plan'){
                    this.products[p].color = 'warning';
                }
                this.products[p].prices = [];
                for (var i =0; i < prices.length; i++){
                    if (prices[i].product === this.products[p].id){
                        this.products[p].prices.push(prices[i]);
                    } 
                }
            }
        },
        openCheckoutDialog(plan){
            this.purchasePlan = plan;
            // console.log("purhcase plan", this.purchasePlan)
            this.checkout = true;
        },
        switchSubscription(plan){
            this.$router.push({
            path: "manage-subscription",
            query: { product: plan.id },
          });
        },
        // isAdmin(){
        //     return firebase.auth.currentUser.uid === 'yzBy9SStgyNr1Ncjf9pkQjfJUO03' || firebase.auth.currentUser.id === "ex18uSUvXReInwQRqaAZxO6Syer2";
        // },
        // async createStripe(){
        //     firebase.db.collection("userData").get()
        //     .then((snapshot) => {
        //         snapshot.forEach( async (doc) => {
        //             if (!doc.data().stripeCustomerId){
        //                 await this.createStripeCustomers(doc.data().email,doc.data().username, doc.id);
        //             }
        //         })
        //     })
        // },
    },
    computed: {
        stripeCustomer(){
            return this.$store.state.stripeCustomer;
        }
    },
    created: async function(){
        // this.createStripe(); //TODO: run this code when we deploy the live keys so we have live customers and live subscriptions
        // this.getStripeProducts();
    }
}
</script>
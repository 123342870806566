<template>
    <v-navigation-drawer v-model="drawerState" width="175" color="info" clipped app disable-route-watcher :temporary="isViewPost">
        <v-list shaped nav>
            <router-link to="/home" icon class="text-decoration-none" @click.native="handleItemClick()">
                <v-list-item link :class="homeActiveClass ? 'active-class' : ''">
                    <v-list-item-icon>
                        <v-icon color="accent">mdi-home</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title class="font-weight-light">Home</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link to="/groups" icon class="text-decoration-none" @click.native="handleItemClick()">
                <v-list-item link :class="groupsActiveClass ? 'active-class' : ''">
                    <v-list-item-icon>
                        <v-icon color="accent">mdi-account-group</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title class="font-weight-light">Groups</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link to="/friends" icon class="text-decoration-none" @click.native="handleItemClick()">
                <v-list-item link :class="friendsActiveClass ? 'active-class' : ''">
                    <v-list-item-icon>
                        <v-icon color="accent">mdi-account-star</v-icon>
                        <!-- <v-icon color="accent">mdi-human-greeting-proximity</v-icon> -->
                        <!-- <v-icon color="accent">mdi-account-supervisor-circle</v-icon> -->
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title class="font-weight-light">Friends</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link to="/profile" icon class="text-decoration-none" @click.native="handleItemClick()">
                <!-- <v-list-item link>
                    <v-list-item-icon>
                        <v-avatar size="25" color="white">
                            <v-img :src="userData.profilePicture === null ? require('../assets/no-user.png') : userData.profilePicture != '' ? userData.profilePicture : require('../assets/no-user.png')" :alt="username"></v-img>
                        </v-avatar>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-light text-truncate">{{username}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item> -->
                <v-list-item link :class="profileActiveClass ? 'active-class' : ''">
                    <v-list-item-icon>
                        <!-- <v-icon color="accent">mdi-account</v-icon> -->
                        <v-icon color="accent">mdi-post</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <!-- <v-list-item-title class="font-weight-light">Profile</v-list-item-title> -->
                        <v-list-item-title class="font-weight-light">My Posts</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <!-- <router-link to="/settings" icon class="text-decoration-none">
                <v-list-item link>
                    <v-list-item-icon>
                        <v-icon color="accent">mdi-settings</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title class="font-weight-light">Settings</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

            <!-- <v-divider class="secondary my-3"></v-divider> -->

            <!-- <router-link to="/drafts" icon class="text-decoration-none">
                <v-list-item link>
                    <v-list-item-icon>
                        <v-icon color="accent">mdi-progress-check</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title class="font-weight-light">Drafts</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

            <!-- <router-link to="/blog" icon class="text-decoration-none">
                <v-list-item link>
                    <v-list-item-icon>
                        <v-icon color="accent">mdi-thought-bubble</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title class="font-weight-light">Blog</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

            <!-- <v-list-item @click="feedbackDialog = true">
                <v-list-item-icon>
                    <v-icon color="accent">mdi-text-box-check</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title class="font-weight-light">Feedback</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <Feedback v-if="feedbackDialog" :dialog="feedbackDialog" @closeFeedback="feedbackDialog = false"/> -->

            <!-- <router-link to="/about" icon class="text-decoration-none">
                <v-list-item link>
                    <v-list-item-icon>
                        <v-icon color="accent">mdi-information-variant</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title class="font-weight-light">About</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

            <!-- <router-link to="/privacy" icon class="text-decoration-none">
                <v-list-item link>
                    <v-list-item-icon>
                        <v-icon color="accent">mdi-account-lock</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title class="font-weight-light">Privacy</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

            <!-- <router-link to="/help" icon class="text-decoration-none">
                <v-list-item link>
                    <v-list-item-icon>
                        <v-icon color="accent">mdi-help</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title class="font-weight-light">Help</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

            <!-- <router-link v-if="isAdmin" to="/admin" icon class="text-decoration-none">
                <v-list-item link>
                    <v-list-item-icon>
                        <v-icon color="accent">mdi-crown</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title class="font-weight-light">Admin</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

        </v-list>

        <v-row justify="space-between" align="end" class="ma-0 pb-lg-5 pb-15 accent--text text-subtitle-1">
            <div class="pl-5 version" style="text-align: left;">
                tawq.in&nbsp;&copy; {{ new Date().getFullYear() }}&nbsp;<br>
                Beta Version 0.5.60
            </div>
        </v-row>

    </v-navigation-drawer>

</template>

<script>
import firebase from "../../firebaseConfig.js";
// import About from './About.vue';
// import Feedback from "./Feedback.vue";

export default {
    name: "drawer",
    props: [],
    components: {
        // Feedback,
        // About,
    },
    data: function (){
        return {
            // feedbackDialog: false,
            username: firebase.auth.currentUser.displayName,
            email: firebase.auth.currentUser.email,
            homeActiveClass: false,
            groupsActiveClass: false,
            friendsActiveClass: false,
            profileActiveClass: false,
        }
    },
    methods: {
        handleItemClick: function () { // used to close the drawer if the user is using a mobile device
            if (this.isMobile) this.drawerState = false;
        },
        updateActiveClass: function (path) {
            // Reset all active classes
            Object.keys(this.activePathState).forEach(key => {
                this[this.activePathState[key]] = false;
            });

            // Set the active class for the current path
            if (this.activePathState[path]) {
                this[this.activePathState[path]] = true;
            }
        },
    },
    watch: {
        '$route.path': {
            immediate: true,
            handler (path) {
                // console.log('path: ', path);
                this.updateActiveClass(path);
            },
        },
    },
    computed: {
        userData(){
            return this.$store.state.userData;
        },
        isAdmin(){
            if (typeof this.userData != 'undefined' && this.userData != null && this.userData.admin) return this.userData.admin;
            return false;
        },
        isViewPost(){
            return this.$route.path === '/view-post' ? true : false;
        },
        isMobile(){
            return this.$store.state.isMobileDevice;
        },
        drawerState: {
            get () {
                return this.$store.state.drawerOpen;
            },
            set (value) { // will get called with 'false' value when clicking off (outside) of drawer on mobile
                this.$store.commit("changeDrawer", value);
            }
        },
        activePathState() {
            return {
                '/home': 'homeActiveClass',
                '/groups': 'groupsActiveClass',
                '/friends': 'friendsActiveClass',
                '/profile': 'profileActiveClass'
            };
        }
    },
    created: function () {
        // if (this.$route.path === '/home') {
        //     this.homeActiveClass = true;
        // }
    },
};
</script>


<style>

.v-navigation-drawer__content {
    display: flex !important;
    flex-direction: column !important;
    /* height: 98% !important; */
    justify-content: space-between !important;
}

.version {
    /* font-size: .60em; */
    font-size: .85em;
}

.active-class {
    /* border: solid var(--v-primary-base) 1px; */
    border: solid var(--v-accent-base) 1px;
}
</style>
